<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start>
                        <h3>Manage {{ module_name }}</h3>
                    </template>
                    <template v-slot:end>
                        <router-link v-if="checkPermission(4, 'view')" to="/access/reviews">
                            <Button v-tooltip.top="'Manage Reviews'" label="Manage Reviews" icon="pi pi-arrow-up-right"
                                class="p-button-raised p-button-md p-button-warning mr-2" />
                        </router-link>
                    </template>
                </Toolbar>

                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start>
                        <Button v-if="checkPermission(moduleId, 'insert')" v-tooltip.top="'Add New'"
                            :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-primary mr-2" />
                        <Button v-if="checkPermission(moduleId, 'view')" v-tooltip.top="'Refresh'"
                            :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-success mr-2" @click="getRecords" />
                        <Button v-if="checkPermission(moduleId, 'export')" v-tooltip.top="'Export'"
                            icon="pi pi-external-link"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''"
                            @click="exportCSV($event)" />
                        <Button v-if="checkPermission(moduleId, 'view')" v-tooltip.top="'Remove Filters'" type="button"
                            icon="pi pi-filter-slash" :label="showBtnLabels ? 'Remove Filters' : ''"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-info mr-2" @click="clearFilter()" />
                        <Button v-if="checkPermission(moduleId, 'delete')" v-tooltip.top="'Delete Selected'"
                            :label="showBtnLabels ? 'Delete Selected' : ''" icon="pi pi-trash"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-danger mr-2" @click="deleteRecord"
                            :disabled="!selectedRecords || !selectedRecords.length" />
                    </template>
                    <template v-slot:end>
                        <Button v-if="checkPermission(moduleId, 'view')" v-tooltip.top="'Helping Material'"
                            :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-info mr-2" />
                    </template>
                </Toolbar>
                <DataTable :reorderableColumns="true" @columnReorder="onColReorder" :resizableColumns="true"
                    columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple"
                    class="p-datatable-gridlines p-datatable-sm" :rows="10" dataKey="id" :rowHover="true"
                    v-model:filters="filters" filterDisplay="menu" :loading="loading" :filters="filters"
                    responsiveLayout="scroll"
                    :globalFilterFields="['name', 'email', 'phone', 'rating', 'description', 'review_date', 'added_by', 'modified_by', 'date_added', 'status']"
                    :rowsPerPageOptions="[10, 20, 50, 100]"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    v-model:selection="selectedRecords" selectionMode="multiple" :scrollable="true" scrollHeight="400px">

                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search"
                                    style="width: 100%" />
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header"
                                @update:modelValue="onToggle" placeholder="Select Columns" style="width: 20em" />
                        </div>
                    </template>

                    <template #empty>
                        <div class="table-message">
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found">
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>

                    <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header"
                        :exportable="col.field == 'action' ? false : true" :sortable="col.field == 'action' ? false : true"
                        :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction' : ''"
                        :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;' : 'max-width:5rem;flex-grow:1; flex-basis:160px;'">
                        <template #body="{ data }">
                            <img v-if="col.field == 'name' && data.profile_pic" :alt="data.name"
                                :src="filesUrl + 'reviews/' + data.profile_pic" class="tableProfileImg mr-2" />
                            <img v-if="col.field == 'name' && !data.profile_pic" :alt="data.name"
                                :src="filesUrl + 'defaults/default.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'name'">{{ data.name }}</span>
                            <span v-if="col.field == 'email'">{{ data.email }}</span>
                            <img v-if="col.field == 'company_name' && data.company_logo" :alt="data.name"
                                :src="filesUrl + 'companies/' + data.company_logo" class="tableProfileImg mr-2" />
                            <img v-if="col.field == 'company_name' && !data.company_logo" :alt="data.name"
                                :src="filesUrl + 'defaults/default-logo.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'company_name'">{{ data.company_name }}</span>
                            <span v-if="col.field == 'rating'">{{ data.rating }}</span>
                            <span v-if="col.field == 'sort_order'">{{ data.sort_order }}</span>
                            <span v-if="col.field == 'review_date'">{{ formatDate(data.review_date) }}</span>
                            <span v-if="col.field == 'phone'">{{ data.phone }}</span>
                            <span v-if="col.field == 'description'">{{ shortStr(data.description, 0, 50) }}</span>
                            <span v-if="col.field == 'date_added'">{{ formatDateTime(data.date_added) }}</span>
                            <span v-if="col.field == 'added_by'">
                                <span v-if="data.added_by">{{ data.added_by }}</span>
                                <span v-else>System</span>
                            </span>
                            <span v-else-if="col.field == 'modified_by'">{{ data.modified_by }}</span>
                            <span v-else-if="col.field == 'date_modification'">{{ formatDateTime(data.date_modification)
                            }}</span>
                            <span v-else-if="col.field == 'status'">
                                <Tag :class="data.status == 'Active' ? 'mr-2 status-Active' : 'mr-2 status-Blocked'"
                                    icon="pi pi-check" :severity="data.status == 'Active' ? 'success' : 'danger'"
                                    :value="data.status"></Tag>
                            </span>
                            <span v-else-if="col.field == 'action'">
                                <div :class="{ 'ml-auto': !isRTL, 'mr-auto': isRTL }">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text"
                                        :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions"></Menu>
                                </div>
                            </span>
                        </template>
                        <template #filter="{ filterModel }">
                            <span v-if="col.field == 'name'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by name" />
                            </span>
                            <span v-if="col.field == 'email'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by email" />
                            </span>
                            <span v-if="col.field == 'company_name'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by company" />
                            </span>
                            <span v-if="col.field == 'review_date'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by Review Date" />
                            </span>
                            <span v-else-if="col.field == 'description'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by about" />
                            </span>
                            <span v-if="col.field == 'added_by'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by name" />
                            </span>
                            <span v-if="col.field == 'modified_by'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by name" />
                            </span>
                            <span v-else-if="col.field == 'date_added'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by Date Added" />
                            </span>
                            <span v-else-if="col.field == 'date_modification'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by Date Modification" />
                            </span>
                            <span v-else-if="col.field == 'status'">
                                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any"
                                    class="p-column-filter" :showClear="true">
                                    <template #value="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{
                                            slotProps.value }}</span>
                                        <span v-else>{{ slotProps.placeholder }}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option">{{ slotProps.option
                                        }}</span>
                                    </template>
                                </Dropdown>
                            </span>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <Toast />
        <ConfirmDialog group="dialog" />
        <!-- form loading -->
        <UserProfileView v-on:UserProfileClose="closeUserProfileView"
            v-if="showUserProfileView && checkPermission(moduleId, 'view')" :userType="userType"
            :recordId="selectedRecordId" :showForm="true" />
        <ReviewForm v-on:formClose="closeForm"
            v-if="showForm && (checkPermission(moduleId, 'insert') || checkPermission(moduleId, 'update'))"
            :recordId="selectedRecordId" :showForm="true" />
        <ModuleHelp v-on:closeHelp="showHelp = false" v-if="showHelp && checkPermission(moduleId, 'view')"
            :moduleId="moduleId" />
    </div>
</template>
<script>
import ReviewForm from '../../components/FrontEnd/ReviewForm.vue';
import ModuleHelp from '../../components/Help/ModuleHelp.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    data() {
        return {
            module_name: 'Reviews',
            actions: [],
            moduleId: 67,
            showHelp: false,
            profilePic: false,
            isSaving: false,
            records: null,
            filters: null,
            loading: true,
            selectedRecords: null,
            columns: null,
            selectedColumns: null,
            showForm: false,
            showUserProfileView: false,
            selectedRecordId: 0,
            saveAndClose: false,
            userType: '',
        }
    },
    components: {
        ReviewForm,
        ModuleHelp
    },
    created() {
        if (this.checkPermission(this.moduleId, 'update')) {
            this.actions.push({
                label: 'Update',
                icon: 'pi pi-refresh',
                command: () => {
                    this.getClickedRecord('update');
                }
            });
        }
        if (this.checkPermission(this.moduleId, 'view')) {
            this.actions.push({
                label: 'View',
                icon: 'pi pi-eye',
                command: () => {
                    this.getClickedRecord('view');
                }
            });
        }
        if (this.checkPermission(this.moduleId, 'delete')) {
            this.actions.push({
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => {
                    this.getClickedRecord('delete');
                }
            });
        }

        this.initFilters();
        this.columns = [
            { field: 'action', header: 'Action' },
            { field: 'company_name', header: 'Company' },
            { field: 'name', header: 'Name' },
            { field: 'email', header: 'Email' },
            { field: 'phone', header: 'Phone' },
            { field: 'rating', header: 'Rating' },
            { field: 'review_date', header: 'Review Date' },
            { field: 'description', header: 'Review' },
            { field: 'sort_order', header: 'Sort Order' },
            { field: 'date_added', header: 'Date Added' },
            { field: 'added_by', header: 'Created By' },
            { field: 'date_added', header: 'Date Added' },
            { field: 'modified_by', header: 'Last Modified By' },
            { field: 'date_modification', header: 'Date Modification' },
            { field: 'status', header: 'Status' },

        ];
        this.selectedColumns = this.columns;
    },
    computed: {
    },
    mounted() {

        //THIS CODE IS TO TABLE COLUMNS SETTINGS
        this.axios.post('getAllModuleColumnSettings', { 'module_id': this.moduleId })
            .then((response) => {
                if (response.data) {
                    if (Object.keys(response.data).length) {
                        this.columns = [];
                        this.selectedColumns = [];
                        response.data.forEach((column) => {
                            this.columns.push({ 'field': column.column_field, 'header': column.column_head });
                            if (column.display) {
                                this.selectedColumns.push({ 'field': column.column_field, 'header': column.column_head });
                            }
                        });
                    }
                }
            })
            .catch(() => {
            })
            .finally(() => {
            })
        //END


        // console.log(JSON.parse(localStorage.getItem('user'))); 
        this.getRecords();

        if (this.$route.query.view) {
            this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
            if (Object.keys(this.urlParams).length) {
                this.urlParams.forEach((parameter) => {
                    if (parameter.view == 'add-review') {
                        this.selectedRecordId = parameter.id;
                        this.showForm = true;
                    }
                })
            }
        }
    },
    methods: {
        onColReorder(event) {
            this.columns = this.tableColumnOrderChange(event, this.columns);
            this.saveTableColumnOrderChange(this.moduleId, this.columns, this.selectedColumns);
        },
        onToggle(value) {
            //this.selectedColumns = this.columns.filter(col => value.includes(col)); 
            this.selectedColumns = [];
            let newColumns = [];
            this.columns.filter((col) => {
                if (value.find(x => x.field === col.field)) {
                    newColumns.push(col);
                    return col;
                }
            });
            this.selectedColumns = newColumns;
            this.saveTableColumnOrderChange(this.moduleId, this.columns, this.selectedColumns);
        },

        getClickedRecord(action) {
            if (action == 'delete') {
                this.deleteRecord();
            }
            else if (action == 'update') {
                this.addViewInURL({ view: 'add-review', id: this.selectedRecordId });
                this.showForm = true;
            }
        },
        toggleRecordMenu(event) {
            this.selectedRecordId = event.currentTarget.getAttribute('recordId');
            this.$refs.menu.toggle(event);
        },
        loadForm() {
            this.selectedRecordId = 0;
            this.addViewInURL({ view: 'add-review', id: this.selectedRecordId });
            this.showForm = true;
        },
        closeForm() {
            this.showForm = false;
            this.removeViewFromURL('add-review');
        },

        getRecords() {
            var Vthis = this;
            this.loading = true;
            this.axios.post('getAllReviews')
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.records = response.data;
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        deleteRecord() {

            var Vthis = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loading = true;
                    let DeleteAbleRecords = [];
                    if (Vthis.selectedRecords) {
                        Vthis.selectedRecordId = '';
                        Vthis.selectedRecords.forEach((data) => {
                            DeleteAbleRecords.push(data.id);
                        })
                    }
                    else {
                        DeleteAbleRecords.push(Vthis.selectedRecordId);
                    }

                    // let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
                    // filterdRecords.forEach((data) => {
                    //     DeleteAbleRecords.push(data.id);
                    // })  

                    Vthis.axios.post('deleteReview', { id: DeleteAbleRecords })
                        .then((response) => {
                            let responseStatus = this.printResponseResult(response, false);
                            if (responseStatus == 200) {
                                Vthis.getRecords();
                                Vthis.showMessage('Record deleted successfully', 'success');
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                            Vthis.loading = false;
                        })
                },
                reject: () => {
                    // this.loading = false;
                }
            });
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },

        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'email': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'phone': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'rating': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'company_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'review_date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'added_by': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'modified_by': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'description': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'date_added': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                'date_modification': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            }
        },
        clearFilter() {
            this.initFilters();
        },
        isRTL() {
            return this.$appState.RTL;
        }
    },
    watch: {
    }
}
</script> 
