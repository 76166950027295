<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="false" @hide="closeForm" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{ module_name }}</h3>
                        <p>You can add or update {{ module_name }} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name" type="text"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="name" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>Email</label>
                                    <InputText id="email" placeholder="Email" v-model="form.email" type="email"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="email" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>Phone</label>
                                    <InputText id="phone" placeholder="Phone" v-model="form.phone" min="0" step="1"
                                        type="number" class="full-width" />
                                    <HasError class="p-error" :form="form" field="phone" />
                                </div>

                                <div class="col-12 lg:col-12 sm:col-12 mb-2"
                                    v-if="checkPermission(16, 'dropdown') && !addCustomer">
                                    <label>Review For Company <span v-if="checkPermission(16, 'insert')"
                                            class="supporting-btn-link" @click="showCompanyForm = true;">+ Add
                                            New</span></label>
                                    <Dropdown v-model="form.company_id" :filter="true" :showClear="true"
                                        :options="companies" optionLabel="name" optionValue="id"
                                        placeholder="Select a Company" @change="selectCompany($event)" class="full-width" />
                                    <HasError class="p-error" :form="form" field="company_id" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>Sort Order</label>
                                    <InputText id="sort_order" placeholder="Sort Order" v-model="form.sort_order" min="0"
                                        step="1" type="number" class="full-width" />
                                    <HasError class="p-error" :form="form" field="sort_order" />
                                </div>
                                <!-- <div v-if="form.company_id > 0 && checkPermission(8, 'dropdown') && !addCustomer"
                                    class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>Branch<span v-if="checkPermission(8, 'insert')" class="supporting-btn-link"
                                            @click="showBranchForm = true;">+ Add New</span></label>
                                    <Dropdown v-model="form.branch_id" :filter="true" :showClear="true" :options="branches"
                                        optionLabel="company_branch_name" optionValue="id" placeholder="Select Branch"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="branch_id" />
                                </div> -->
                                <!-- <div v-if="form.company_id > 0 && checkPermission(9, 'dropdown') && !addCustomer"
                                    class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>Department<span v-if="checkPermission(9, 'insert')" class="supporting-btn-link"
                                            @click="showDepartmentForm = true;">+ Add New</span></label>
                                    <Dropdown v-model="form.department_id" :filter="true" :showClear="true"
                                        :options="departments" optionLabel="company_dep_name" optionValue="id"
                                        placeholder="Select Department" class="full-width" />
                                    <HasError class="p-error" :form="form" field="department_id" />
                                </div> -->
                                <!-- <div v-if="form.company_id > 0 && checkPermission(11, 'dropdown') && !addCustomer"
                                    class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>Designation<span v-if="checkPermission(11, 'insert')" class="supporting-btn-link"
                                            @click="showDesignationForm = true;">+ Add New</span></label>
                                    <Dropdown v-model="form.designation_id" :filter="true" :showClear="true"
                                        :options="designations" optionLabel="company_designation_name" optionValue="id"
                                        placeholder="Select Designation" class="full-width" />
                                    <HasError class="p-error" :form="form" field="designation_id" />
                                </div> -->

                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label> Review Date</label>
                                    <Calendar ref="calendar1" panelClass="calendarStyle" :dateFormat="calendarFormat()"
                                        @date-select="startDateTimeSelected($event)" :showButtonBar="true" id="review_date"
                                        v-model="form.review_date" :showTime="false" :showSeconds="false"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="rating" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Rating</label>
                                    <InputText id="rating" placeholder="Rating" v-model="form.rating" min="0" max="5"
                                        step="0.1" type="number" class="full-width" />
                                    <HasError class="p-error" :form="form" field="rating" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>Review Description</label>
                                    <Textarea placeholder="Review Description" v-model="form.description" rows="5" cols="30"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" v-if="!addCustomer">
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name"
                                        optionValue="id" placeholder="Select Status" class="full-width" />
                                    <HasError class="p-error" :form="form" field="status" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <Panel v-if="profilePic" class="mb-3" header="Current Profile Picture" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2 profileCardImg">
                                        <div>
                                            <img alt="Profile Picture" :src="profilePic">
                                        </div>
                                        <div v-if="checkPermission(moduleId, 'delete')">
                                            <ConfirmPopup group="popup"></ConfirmPopup>
                                            <Button ref="popup" @click="confirmFileDeletion($event)" icon="pi pi-times"
                                                label="Remove" class="p-button-danger p-button-raised p-button-md"
                                                style="margin-left: .5em" />
                                        </div>
                                    </div>
                                </Panel>
                                <Panel class="mb-3" header="Profile Picture" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                        <FileUpload name="profile_pic" :showUploadButton="false" :customUpload="true"
                                            @select="myUploader" :multiple="false" accept="image/*" :maxFileSize="1000000">
                                        </FileUpload>
                                    </div>
                                </Panel>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()"
                                    class="p-button-raised p-button-lg p-button-success mr-2" />
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check"
                                    @click="saveAndClose = true, save()"
                                    class="p-button-raised p-button-lg p-button-info mr-2" />
                                <Button type="button" label="Reset" icon="pi pi-refresh"
                                    class="p-right p-button-raised p-button-lg p-button-warning mr-2"
                                    @click="resetForm()" />
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>
        <CompanyForm v-on:formClose="closeCompanyForm" v-if="showCompanyForm && (checkPermission(16, 'insert'))"
            :recordId="0" :showForm="true" />
    </BlockUI>
</template>
<script>
import CompanyForm from '../../components/Companies/CompanyForm.vue';
export default {
    data() {
        return {
            form: new this.Form({
                id: '',
                name: '',
                company_id: 0,
                email: '',
                profile_pic: '',
                phone: '',
                rating: 0,
                sort_order: 0,
                review_date: '',
                status: 1,
                description: '',

            }),
            // selectAllAL:false,
            module_name: 'Reviews',
            moduleId: 67,
            companies: [],
            isSaving: false,
            saveAndClose: false,
            recId: 0,
            loadingComponent: true,
            formVisible: false,
            showCompanyForm: false,
        }
    },
    props: {
        showForm: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
    },
    components: {
        CompanyForm,
    },
    created() {
        this.modulePermissions = this.permissions[this.moduleId];
    },
    computed: {
    },
    mounted() {
        this.resetForm();
        this.formVisible = this.showForm;
        this.recId = this.recordId;
        this.getAllRequestData();
    },
    methods: {
        startDateTimeSelected($event) {
            this.form.review_date = '';
            var selectedDate = new Date($event);
            this.minDateTime = selectedDate;
            this.form.review_date = new Date($event);
            this.form.review_date = (selectedDate.getMonth() + 1) + '/' + (selectedDate.getDate()) + '/' + selectedDate.getFullYear();
            this.form.review_date = this.formatDate(this.form.review_date);
        },
        getAllRequestData() {
            this.$axios.all([this.getCompanies()])
                .then(() => {
                    this.updateRecord();
                })
                .then(() => {
                    this.blockComponent = false;
                }).catch(() => {
                    //    this.getAllRequestData();
                })
                .finally(() => {
                });
        },

        closeCompanyForm() {
            this.getCompanies();
            this.showCompanyForm = false;
        },



        selectAllVehicleTypes() {
            if (!this.AllVehicleTypes) {
                this.vehicleTypes.forEach((type) => {
                    this.form.vehicleTypes.push(type.id);
                })
            }
            else {
                this.form.vehicleTypes = [];
            }
        },


        selectCompany(e) {
            var company = e.value;
        },
        async getCompanies() {
            var Vthis = this;
            if (!this.form.company_id) {
                this.form.company_id = this.userData['company_id'];
            }
            await Vthis.axios.post('getAllCompanies', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.companies = response.data;
                        if (this.form.company_id == 0) {
                            this.form.company_id = response.data[0].id;
                        }


                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        resetForm() {
            this.form.reset();
        },
        async save() {
            var Vthis = this;
            this.isSaving = true;
            await this.form
                .post(Vthis.$baseurl + 'api/saveReview', Vthis.form)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        Vthis.showMessage('Saved successfully', 'success');
                        if (!this.recId) {
                            this.resetForm();
                        }
                        // this.getRecords();
                        if (this.saveAndClose) {
                            this.saveAndClose = false;
                            setTimeout(() => { this.closeForm(); }, 1000);
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false;
                })
        },
        loadForm() {
            this.resetForm();
        },
        closeForm() {
            this.$emit('formClose', true);
        },
        myUploader(event) {
            this.form.profile_pic = event.files;
        },
        async updateRecord() {
            if (this.recId > 0) {
                var Vthis = this;
                this.loading = true;
                await this.axios.post('getAllReviews', { id: this.recId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            Vthis.form.fill(response.data[0]);
                            Vthis.profilePic = false;
                            if (response.data[0].profile_pic) {
                                Vthis.profilePic = Vthis.filesUrl + "reviews/" + response.data[0].profile_pic;
                            }
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.loading = false;
                    })
            }
        },
        async removeProfilePic() {
            var Vthis = this;
            await Vthis.axios.post('deleteReviewProfilePic', { id: this.form.id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response) {
                            Vthis.profilePic = false;
                            Vthis.showMessage('Deleted Successfully', 'success');
                        }
                        else {
                            Vthis.showMessage('Not deleted! Please try later', 'error');
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        confirmFileDeletion($event) {
            this.$confirm.require({
                target: $event.currentTarget,
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.removeProfilePic();
                },
                reject: () => {

                }
            });
        },
    },
    watch: {
    }
}
</script> 
